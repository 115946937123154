<template>
  <div>
    <!--*******************
        Preloader start
    ********************-->
    <div id="preloader">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <!--*******************
        Preloader end
    ********************-->

    <!--**********************************
        Main wrapper start
    ***********************************-->
    <div id="main-wrapper">
      <!--**********************************
            Nav header start
        ***********************************-->
      <div class="nav-header" style="color: black; background-color: white">
        <a href="/" class="brand-logo">
          <img class="logo-abbr" src="@/assets/imgs/logo-small.png" alt="" />
          <p class="brand-title">
            {{ this.$store.getters.getSchoolNameAbbreviation }}
          </p>
          <!-- <p class="logo-compact">{{this.$store.getters.getSchoolName}}</p> -->
          <!-- <img class="logo-compact" src="images/logo-text-white.png" alt=""> -->
          <!-- <img class="brand-title" src="images/logo-text-white.png" alt=""> -->
        </a>

        <div class="nav-control">
          <div class="hamburger">
            <span class="line"></span><span class="line"></span
            ><span class="line"></span>
          </div>
        </div>
      </div>
      <!--**********************************
            Nav header end
        ***********************************-->

      <!--**********************************
            Header start
        ***********************************-->
      <div class="header">
        <div class="header-content">
          <nav class="navbar navbar-expand">
            <div class="collapse navbar-collapse justify-content-between">
              <div class="header-left">
                <div class="search_bar dropdown">
                  <span
                    class="search_icon p-3 c-pointer"
                    data-toggle="dropdown"
                    style="background-color: #aa792f !important"
                  >
                    <i class="mdi mdi-magnify"></i>
                  </span>
                  <div class="dropdown-menu p-0 m-0">
                    <form>
                      <input
                        class="form-control"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                      />
                    </form>
                  </div>
                </div>
              </div>

              <ul class="navbar-nav header-right">
                <li class="nav-item dropdown notification_dropdown">
                  <a
                    class="nav-link bell ai-icon"
                    href="#"
                    role="button"
                    data-toggle="dropdown"
                  >
                    <svg
                      id="icon-user"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-bell"
                    >
                      <path
                        d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"
                      ></path>
                      <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                    </svg>
                    <div class="pulse-css"></div>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <ul class="list-unstyled">
                      <li class="media dropdown-item">
                        <p>You haven't any new notifications</p>
                      </li>
                      <!-- <li class="media dropdown-item">
                        <span class="success"><i class="ti-user"></i></span>
                        <div class="media-body">
                          <a href="#">
                            <p><strong>Martin</strong> has added a <strong>customer</strong> Successfully
                            </p>
                          </a>
                        </div>
                        <span class="notify-time">3:20 am</span>
                      </li> -->
                    </ul>
                    <!-- <a class="all-notification" href="#">See all notifications <i class="ti-arrow-right"></i></a> -->
                  </div>
                </li>
                <li class="nav-item dropdown header-profile">
                  <a
                    class="nav-link"
                    href="#"
                    role="button"
                    data-toggle="dropdown"
                  >
                    <!-- <img src="" width="20" alt="" /> -->
                    <i class="la la-gear"></i>
                    {{ this.$store.getters.getUserFirstName }}
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <!-- <a href="#" class="dropdown-item ai-icon">
                      <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-user">
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                        <circle cx="12" cy="7" r="4"></circle>
                      </svg>
                      <span class="ml-2">Profile </span>
                    </a> -->

                    <a
                      @click="this.$store.dispatch('logout')"
                      href="javascript:void(0)"
                      class="dropdown-item ai-icon"
                    >
                      <svg
                        id="icon-logout"
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-log-out"
                      >
                        <path
                          d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"
                        ></path>
                        <polyline points="16 17 21 12 16 7"></polyline>
                        <line x1="21" y1="12" x2="9" y2="12"></line>
                      </svg>
                      <span class="ml-2">Logout </span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <!--**********************************
            Header end ti-comment-alt
        ***********************************-->

      <!--**********************************
            Sidebar start
        ***********************************-->
      <div class="deznav">
        <div class="deznav-scroll">
          <ul class="metismenu" id="menu">
            <li>
              <router-link :to="{ name: 'Dashboard-Home' }">
                <i class="custom-char-icon">D</i>
                <span class="nav-text">Dashboard</span>
              </router-link>
            </li>

            <li v-can="'access_applications'">
              <router-link :to="{ name: 'Applications' }">
                <i class="custom-char-icon">A</i>
                <span class="nav-text">Applications</span>
              </router-link>
            </li>
            <li v-canAny="'access_payment_profiles'">
              <router-link :to="{ name: 'Payment-Profiles' }">
                <i class="custom-char-icon">P</i>
                <span class="nav-text">Payment Profiles</span>
              </router-link>
            </li>

            <li v-canAny="'access_students,access_student_transactions'">
              <router-link :to="{ name: 'Student-Affairs' }">
                <i class="custom-char-icon">S</i>
                <span class="nav-text">Student Affairs</span>
              </router-link>
            </li>

            <li v-canAny="'access_levels'">
              <router-link :to="{ name: 'Levels' }">
                <i class="custom-char-icon">L</i>
                <span class="nav-text">Levels</span>
              </router-link>
            </li>

            <li v-canAny="'access_staff'">
              <router-link :to="{ name: 'Staff-Affairs' }">
                <i class="custom-char-icon">S</i>
                <span class="nav-text">Staff Affairs</span>
              </router-link>
            </li>

            <li v-canAny="'access_academic_years'">
              <router-link :to="{ name: 'Academic-Years' }">
                <i class="custom-char-icon">A</i>
                <span class="nav-text">Academic Years</span>
              </router-link>
            </li>

            <li
              v-canAny="
                'access_financial_accounts,access_financial_headings,access_financial_transactions'
              "
            >
              <router-link :to="{ name: 'Financials' }">
                <i class="custom-char-icon">F</i>
                <span class="nav-text">Financials</span>
              </router-link>
            </li>

            <li v-can="'access_eWallet'">
              <router-link :to="{ name: 'eWallet' }">
                <i class="custom-char-icon">e</i>
                <span class="nav-text">eWallet</span>
              </router-link>
            </li>

            <li v-can="'access_buses'">
              <router-link :to="{ name: 'Buses' }">
                <i class="custom-char-icon">B</i>
                <span class="nav-text">Buses</span>
              </router-link>
            </li>

            <li v-userRole="'Teacher'">
              <router-link :to="{ name: 'Teachers-Subjects-My' }">
                <i class="custom-char-icon">S</i>
                <span class="nav-text">My Subjects </span>
              </router-link>
            </li>

            <li v-userRole="'Parent'">
              <router-link :to="{ name: 'Student-Payments-Childrens-List' }">
                <i class="custom-char-icon">P</i>
                <span class="nav-text">My Payments</span>
              </router-link>
            </li>

            <li v-userRole="'Parent'">
              <router-link :to="{ name: 'Parent-Subjects-Childrens-List' }">
                <i class="custom-char-icon">S</i>
                <span class="nav-text">My Subjects </span>
              </router-link>
            </li>

            <li v-userRole="'Parent'">
              <router-link :to="{ name: 'Parent-eWallet' }">
                <i class="custom-char-icon">e</i>
                <span class="nav-text">eWallet </span>
              </router-link>
            </li>

            <li v-userRole="'Parent'">
              <router-link :to="{ name: 'Parent-Buses' }">
                <i class="custom-char-icon">B</i>
                <span class="nav-text">Buses</span>
              </router-link>
            </li>

            <li v-userRole="'Student'">
              <router-link
                :to="{
                  name: 'Student-Payments-Single',
                  params: { user_id: this.$store.getters.getLoggedInUserId },
                }"
              >
                <i class="custom-char-icon">P</i>
                <span class="nav-text">My Payments </span>
              </router-link>
            </li>

            <li v-userRole="'Student'">
              <router-link
                :to="{
                  name: 'Parent-Subjects-list',
                  params: { student_id: this.$store.getters.getLoggedInUserId },
                }"
              >
                <i class="custom-char-icon">S</i>
                <span class="nav-text">My Subjects </span>
              </router-link>
            </li>

            <li v-userRole="'Student'">
              <router-link :to="{ name: 'eWallet-qrCode' }">
                <i class="custom-char-icon">e</i>
                <span class="nav-text">eWallet QrCode</span>
              </router-link>
            </li>

            <!-- <li><a class="has-arrow" href="javascript:void()" aria-expanded="false">
                <i class="la la-desktop"></i>
                <span class="nav-text">Test</span>
              </a>
              <ul aria-expanded="false">
                <li>
                  <router-link :to="{name: 'Haitham'}">Haitham</router-link>
                </li>
                <li><a href="#">Test 2</a></li>
                <li><a href="#">Test 3</a></li>
              </ul>
            </li>
            <li><a class="has-arrow" href="javascript:void()" aria-expanded="false">
                <i class="la la-desktop"></i>
                <span class="nav-text">Test2</span>
              </a>
              <ul aria-expanded="false">
                <li>
                  <router-link :to="{name: 'Haitham'}">Haitham</router-link>
                </li>
                <li><a href="#">Test 2</a></li>
                <li><a href="#">Test 3</a></li>
              </ul>
            </li> -->
          </ul>
        </div>
      </div>
      <!--**********************************
            Sidebar end
        ***********************************-->

      <!--**********************************
            Content body start
        ***********************************-->
      <div class="content-body">
        <!-- row -->
        <div class="container-fluid">
          <router-view />
        </div>
      </div>
      <!--**********************************
            Content body end
        ***********************************-->

      <!--**********************************
            Footer start
        ***********************************-->
      <div class="footer" style="border-top: 1px solid #8080808f">
        <div class="copyright" style="text-align: center">
          <span>
            Copyright © 2021 {{ this.$store.getters.getSchoolName }} all rights
            reserved, Developed By
            <a
              href="https://www.linkedin.com/in/haithamahmed113"
              target="_blank"
              style="color: blue"
              >Haitham Ahmed</a
            >, Based on EduZone - Theme Forest
          </span>
        </div>
      </div>
      <!--**********************************
            Footer end
        ***********************************-->

      <!--**********************************
           Support ticket button start
        ***********************************-->

      <!--**********************************
           Support ticket button end
        ***********************************-->
    </div>
    <!--**********************************
        Main wrapper end
    ***********************************-->
  </div>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
export default {
  setup() {
    // mounted
    onMounted(() => {
      //this to call the native js libs and plugins, which is need the dom to load first
      window.afterVuejsLoaded();
      //  $('#staticBackdrop2').modal('show')
    });
  },
};
</script>

<style>
.deznav {
  background-image: url("~@/assets/imgs/pt15.png") !important;
}

.custom-char-icon {
  border: 1px solid !important;
  padding: 0px 4px !important;
  margin-right: 10px !important;
  border-radius: 5px !important;
  font-style: normal;
}
</style>
